//
// Aside dark theme
//


// Initialization of global variables, mixins and functions
@import "../../../init";

.aside {
	background-color: #FF4242;
	background-color: linear-gradient(160deg, rgba(102,93,219,1) 0%, rgba(73,64,184,1) 63%, rgba(53,44,160,1) 100%);
	// Aside Menu
	.aside-menu {
		// Scrollbar
		@include perfect-scrollbar-theme(#637099);
	}
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, dark);
